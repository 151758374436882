import React from 'react'
import Svg from '../Svg'
import { SvgProps } from '../types'

const Icon: React.FC<SvgProps> = (props) => {
  return (
    <Svg {...props} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 30 23">
      <path d="M24.8765 2.68453C23.06 1.85105 21.112 1.23697 19.0754 0.885264C19.0383 0.878477 19.0012 0.89544 18.9821 0.929367C18.7316 1.37493 18.4541 1.95621 18.2598 2.4131C16.0692 2.08515 13.8899 2.08515 11.7443 2.4131C11.5499 1.94606 11.2623 1.37493 11.0107 0.929367C10.9916 0.896572 10.9545 0.879609 10.9174 0.885264C8.88189 1.23585 6.93398 1.84992 5.11636 2.68453C5.10063 2.69131 5.08714 2.70263 5.07819 2.71732C1.3834 8.23725 0.371247 13.6215 0.867776 18.939C0.870023 18.965 0.884627 18.9899 0.904848 19.0057C3.34256 20.7959 5.7039 21.8827 8.02139 22.6031C8.05848 22.6144 8.09778 22.6009 8.12138 22.5703C8.66959 21.8217 9.15826 21.0323 9.57725 20.2022C9.60198 20.1536 9.57838 20.0959 9.52784 20.0767C8.75272 19.7826 8.01465 19.4241 7.30468 19.017C7.24852 18.9842 7.24403 18.9039 7.29569 18.8655C7.44509 18.7535 7.59454 18.637 7.7372 18.5194C7.76301 18.4979 7.79897 18.4934 7.82932 18.507C12.4935 20.6365 17.5431 20.6365 22.1523 18.507C22.1826 18.4923 22.2186 18.4968 22.2455 18.5183C22.3882 18.6359 22.5376 18.7535 22.6881 18.8655C22.7398 18.9039 22.7364 18.9842 22.6803 19.017C21.9703 19.4321 21.2322 19.7826 20.456 20.0756C20.4054 20.0948 20.383 20.1536 20.4077 20.2022C20.8357 21.0311 21.3244 21.8205 21.8624 22.5692C21.8849 22.6009 21.9253 22.6144 21.9624 22.6031C24.2912 21.8827 26.6525 20.7959 29.0902 19.0057C29.1116 18.9899 29.125 18.9661 29.1273 18.9401C29.7215 12.7925 28.132 7.45242 24.9135 2.71845C24.9056 2.70263 24.8922 2.69131 24.8765 2.68453ZM10.2738 15.7012C8.86953 15.7012 7.71247 14.412 7.71247 12.8287C7.71247 11.2454 8.84709 9.95624 10.2738 9.95624C11.7117 9.95624 12.8575 11.2568 12.835 12.8287C12.835 14.412 11.7004 15.7012 10.2738 15.7012ZM19.7438 15.7012C18.3396 15.7012 17.1825 14.412 17.1825 12.8287C17.1825 11.2454 18.3171 9.95624 19.7438 9.95624C21.1817 9.95624 22.3275 11.2568 22.3051 12.8287C22.3051 14.412 21.1817 15.7012 19.7438 15.7012Z" />
    </Svg>
  )
}

export default Icon
