import React from 'react'
import Svg from '../Svg'
import { SvgProps } from '../types'

const Icon: React.FC<SvgProps> = (props) => {
  return (
    <Svg {...props} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 22 20" fill="none">
      <path
        d="M1.1 20C0.788333 20 0.527083 19.8935 0.31625 19.6806C0.105417 19.4676 0 19.2037 0 18.8889C0 18.5741 0.105417 18.3102 0.31625 18.0972C0.527083 17.8843 0.788333 17.7778 1.1 17.7778H20.9C21.2117 17.7778 21.4729 17.8843 21.6838 18.0972C21.8946 18.3102 22 18.5741 22 18.8889C22 19.2037 21.8946 19.4676 21.6838 19.6806C21.4729 19.8935 21.2117 20 20.9 20H1.1ZM2.75 16.6667C2.29167 16.6667 1.90208 16.5046 1.58125 16.1806C1.26042 15.8565 1.1 15.463 1.1 15V10.5556C1.1 10.0926 1.26042 9.69907 1.58125 9.375C1.90208 9.05093 2.29167 8.88889 2.75 8.88889C3.20833 8.88889 3.59792 9.05093 3.91875 9.375C4.23958 9.69907 4.4 10.0926 4.4 10.5556V15C4.4 15.463 4.23958 15.8565 3.91875 16.1806C3.59792 16.5046 3.20833 16.6667 2.75 16.6667ZM8.25 16.6667C7.79167 16.6667 7.40208 16.5046 7.08125 16.1806C6.76042 15.8565 6.6 15.463 6.6 15V5C6.6 4.53704 6.76042 4.14352 7.08125 3.81944C7.40208 3.49537 7.79167 3.33333 8.25 3.33333C8.70833 3.33333 9.09792 3.49537 9.41875 3.81944C9.73958 4.14352 9.9 4.53704 9.9 5V15C9.9 15.463 9.73958 15.8565 9.41875 16.1806C9.09792 16.5046 8.70833 16.6667 8.25 16.6667ZM13.75 16.6667C13.2917 16.6667 12.9021 16.5046 12.5813 16.1806C12.2604 15.8565 12.1 15.463 12.1 15V8.33333C12.1 7.87037 12.2604 7.47685 12.5813 7.15278C12.9021 6.8287 13.2917 6.66667 13.75 6.66667C14.2083 6.66667 14.5979 6.8287 14.9187 7.15278C15.2396 7.47685 15.4 7.87037 15.4 8.33333V15C15.4 15.463 15.2396 15.8565 14.9187 16.1806C14.5979 16.5046 14.2083 16.6667 13.75 16.6667ZM19.25 16.6667C18.7917 16.6667 18.4021 16.5046 18.0812 16.1806C17.7604 15.8565 17.6 15.463 17.6 15V1.66667C17.6 1.2037 17.7604 0.810185 18.0812 0.486111C18.4021 0.162037 18.7917 0 19.25 0C19.7083 0 20.0979 0.162037 20.4188 0.486111C20.7396 0.810185 20.9 1.2037 20.9 1.66667V15C20.9 15.463 20.7396 15.8565 20.4188 16.1806C20.0979 16.5046 19.7083 16.6667 19.25 16.6667Z"
        fill="#FFAA00"
      />
    </Svg>
  )
}

export default Icon
