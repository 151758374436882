import { useEffect, useMemo, useState } from 'react'
import { useWeb3React, UnsupportedChainIdError } from '@web3-react/core'
import {
  Flex,
  // LogoutIcon,
  // RefreshIcon,
  useModal,
  UserMenu as UIKitUserMenu,
  // UserMenuDivider,
  // UserMenuItem,
  UserMenuVariant,
  Box,
  // WalletIcon,
  Text,
  // Image,
  // Link,
  // CloseIcon,
} from 'packages/uikit'
import Trans from 'components/Trans'
// import useAuth from 'hooks/useAuth'
import { useDomainInfo } from 'hooks/useDomainInfo'
// import { useRouter } from 'next/router'
import { useProfile } from 'state/profile/hooks'
import { usePendingTransactions } from 'state/transactions/hooks'
import ConnectWalletButton from 'components/ConnectWalletButton'
import { useTranslation } from 'contexts/Localization'
// import { nftsBaseUrl } from 'views/Nft/market/constants'
// import { getBscScanLink } from 'utils'
import styled from 'styled-components'
import WalletModal, { WalletView } from './WalletModal'
// import ProfileUserMenuItem from './ProfileUserMenuItem'
// import WalletUserMenuItem from './WalletUserMenuItem'
import CopyAddress from './CopyAddress'
import TransactionsHistories from './TransactionsHistories'

const StyledBlock = styled.div`
  width: 100%;
  padding: 10px 20px;
  max-width: 100%;

  ${({ theme }) => theme.mediaQueries.md} {
    padding: 20px;
  }
`

const UserMenu = () => {
  const { t } = useTranslation();
  const { account, error, chainId } = useWeb3React();
  const { hasPendingTransactions, pendingNumber } = usePendingTransactions();
  // const { profile } = useProfile();
  // const [onPresentWalletModal] = useModal(<WalletModal initialView={WalletView.WALLET_INFO} />);
  // const [onPresentWrongNetworkModal] = useModal(<WalletModal initialView={WalletView.WRONG_NETWORK} />);
  // const avatarSrc = profile?.nft?.image?.thumbnail
  const [userMenuText, setUserMenuText] = useState<string>('');
  const [userMenuVariable, setUserMenuVariable] = useState<UserMenuVariant>('default');
  const isWrongNetwork: boolean = error instanceof UnsupportedChainIdError;

  const { data: domainData } = useDomainInfo({
    chain: chainId || 1,
    address: account || '',
  });

  useEffect(() => {
    if (hasPendingTransactions) {
      setUserMenuText(t('%num% Pending', { num: pendingNumber }))
      setUserMenuVariable('pending')
    } else {
      setUserMenuText('')
      setUserMenuVariable('default')
    }
  }, [hasPendingTransactions, pendingNumber, t])

  // const onClickWalletMenu = (): void => {
  //   if (isWrongNetwork) {
  //     onPresentWrongNetworkModal()
  //   } else {
  //     onPresentWalletModal()
  //   }
  // }

  const domainName = useMemo(() => domainData?.primaryDomain, [domainData]);

  const UserMenuItems = () => {
    return (
      <StyledBlock>
        <Text color="white">{t('Account')}</Text>

        <Flex flexDirection="column" width="100%" alignItems="center" style={{ gap: '5px' }}>
          <Box mb="16px" width="100%">
            <CopyAddress account={account} />
          </Box>

          <Text mb="12px" fontSize="16px" color="white" textAlign="center">
            {t('Your history transactions')}
          </Text>

          <TransactionsHistories />
        </Flex>
      </StyledBlock>
    )
  }

  if (account) {
    return (
      <UIKitUserMenu
        account={domainName || account}
        ellipsis={!domainName}
        text={userMenuText}
        variant={userMenuVariable}
      >
        {({ isOpen }) => (isOpen ? <UserMenuItems /> : null)}
      </UIKitUserMenu>
    )
  }

  if (isWrongNetwork) {
    return (
      <UIKitUserMenu text={t('Network')} variant="danger">
        {({ isOpen }) => (isOpen ? <WalletModal initialView={WalletView.WRONG_NETWORK} /> : null)}
      </UIKitUserMenu>
    )
  }

  return (
    <ConnectWalletButton
      style={{
        color: 'black',
        padding: '0 16px',
      }}
      height={['36px', , , , '48px']}
    >
      <Box display={['none', , , 'block']}>
        <Flex alignItems="center">
          {/* <WalletIcon mr="5px" color="white" /> */}
          <Trans>Connect Wallet</Trans>
        </Flex>
      </Box>
      <Box display={['block', , , 'none']}>
        <Trans>Connect</Trans>
      </Box>
    </ConnectWalletButton>
  )
}

export default UserMenu
