import { useDispatch } from 'react-redux'
import { AppDispatch } from 'state'
import { isTransactionRecent, useAllTransactions } from 'state/transactions/hooks'
import useActiveWeb3React from 'hooks/useActiveWeb3React'
import { clearAllTransactions } from 'state/transactions/actions'
import orderBy from 'lodash/orderBy'
import { Text, Box, HistoriesIcon, Button, Flex } from 'packages/uikit'
import { useTranslation } from 'contexts/Localization'
import { FlexGap } from 'components/Layout/Flex'
import styled from 'styled-components'
import TransactionRow from './TransactionRow'

const StyledBox = styled(Box)`
  display: flex;
  flex-direction: column;
  width: 100%;
`

const StyledList = styled(Flex)`
  display: flex;
  flex-direction: column;
  padding: 5px 0 0;

  width: 100%;
  max-height: 250px;
  overflow-y: auto;
  overflow-x: hidden;
`

const TransactionsHistories = () => {
  const { chainId } = useActiveWeb3React()
  const dispatch = useDispatch<AppDispatch>()
  const allTransactions = useAllTransactions()
  const sortedTransactions = orderBy(Object.values(allTransactions).filter(isTransactionRecent), 'addedTime', 'desc')
  const { t } = useTranslation()

  const handleClearAll = () => {
    if (chainId) {
      dispatch(clearAllTransactions({ chainId }))
    }
  }

  return (
    <>
      <Flex width="100%" mb="12px" justifyContent="space-between" alignItems="center">
        <Text small>{t('Recent Transactions')}</Text>
        <Text style={{ cursor: 'pointer' }} small color="success" onClick={handleClearAll}>
          {t('Clear all')}
        </Text>
      </Flex>
      <StyledList>
        {sortedTransactions.length > 0 ? (
          sortedTransactions.map((txn) => (
            <StyledBox key={txn.hash}>
              <TransactionRow txn={txn} />
            </StyledBox>
          ))
        ) : (
          <FlexGap gap="10px" mx="auto">
            <HistoriesIcon />
            <Text style={{ fontStyle: 'italic' }} small color="white" textAlign="center">
              {t('You have no recent transactions')}
            </Text>
          </FlexGap>
        )}
      </StyledList>
    </>
  )
}

export default TransactionsHistories
