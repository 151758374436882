import { createGlobalStyle } from 'styled-components'
import { PancakeTheme } from 'packages/uikit'

declare module 'styled-components' {
  /* eslint-disable @typescript-eslint/no-empty-interface */
  export interface DefaultTheme extends PancakeTheme {}
}

const GlobalStyle = createGlobalStyle`
  :root {
    --modal-box-shadow: 3px 3px 0px 0px rgba(251, 238, 185, 1);
    // colors
    --primary-color: ${({ theme }) => theme.colors.primary};
    --input-color: rgba(142, 71, 25, 1);
    --button-color: rgba(253, 183, 45, 1);
    --icon-button-color: rgba(251, 238, 185, 1);
    --black-color: rgba(0,0,0,1);
    --border-color: rgba(251, 238, 185, 1);
    --text-color: rgba(255, 193, 7, 1);
    --table-th-color: rgba(44, 26, 22, 1);
    --not-active-color: rgba(217, 217, 217, 1);
  }
  * {
    font-family: 'Roboto', sans-serif;
  }
  body {
    background-color: ${({ theme }) => theme.colors.background};

    img {
      height: auto;
      max-width: 100%;
    }
  }
`

export default GlobalStyle
