import { useState, useEffect, useCallback } from 'react';

const apiEndPoint = 'https://zns.bio/api'

interface DomainData {
  code: number;
  primaryDomain: string;
  userOwnedDomains: string[];
}

interface UseDomainInfoProps {
  chain: number;
  address: string;
}

export const useDomainInfo = ({ chain, address }: UseDomainInfoProps) => {
  const [data, setData] = useState<DomainData | null>(null);
  const [error, setError] = useState<string | null>(null);

  const fetchDomainInfo = useCallback(async () => {
    if (!chain || !address) return;

    setError(null);

    try {
      const response = await fetch(`${apiEndPoint}/resolveAddress?chain=${chain}&address=${address}`);
      if (!response.ok) throw new Error('Failed to fetch data');

      const result: DomainData = await response.json();
      setData(result);
    } catch (err: any) {
      setError(err.message || 'Failed to fetch data');
    }
  }, [chain, address]);

  useEffect(() => {
    fetchDomainInfo();
  }, [fetchDomainInfo]);

  return { data, error };
};
