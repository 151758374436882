import React from 'react'
import Svg from '../Svg'
import { SvgProps } from '../types'

const Icon: React.FC<SvgProps> = (props) => {
  return (
    <Svg {...props} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 30 30">
      <path
        d="M16.2136 13.7727C13.7864 12.9682 12.6136 12.4636 12.6136 11.1818C12.6136 9.79091 14.1273 9.28636 15.0818 9.28636C16.8682 9.28636 17.5227 10.6364 17.6727 11.1136L19.8273 10.2C19.6227 9.58636 18.7091 7.58182 16.3636 7.14545V5.45455H13.6364V7.17273C10.2545 7.93636 10.2409 11.0727 10.2409 11.2091C10.2409 14.3045 13.3091 15.1773 14.8091 15.7227C16.9636 16.4864 17.9182 17.1818 17.9182 18.4909C17.9182 20.0318 16.4864 20.6864 15.2182 20.6864C12.7364 20.6864 12.0273 18.1364 11.9455 17.8364L9.68182 18.75C10.5409 21.7364 12.7909 22.5409 13.6364 22.7864V24.5455H16.3636V22.8545C16.9091 22.7318 20.3182 22.05 20.3182 18.4636C20.3182 16.5682 19.4864 14.9045 16.2136 13.7727ZM2.72727 27.2727H0V19.0909H8.18182V21.8182H4.8C6.99545 25.1045 10.7455 27.2727 15 27.2727C21.7773 27.2727 27.2727 21.7773 27.2727 15H30C30 23.2909 23.2909 30 15 30C9.92727 30 5.44091 27.4773 2.72727 23.6318V27.2727ZM0 15C0 6.70909 6.70909 0 15 0C20.0727 0 24.5591 2.52273 27.2727 6.36818V2.72727H30V10.9091H21.8182V8.18182H25.2C23.0045 4.89545 19.2545 2.72727 15 2.72727C8.22273 2.72727 2.72727 8.22273 2.72727 15H0Z"
        fill="#FFAA00"
      />
    </Svg>
  )
}

export default Icon
