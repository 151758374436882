/* eslint-disable react/no-array-index-key */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { createElement, memo } from 'react'
import styled from 'styled-components'
import { Flex } from '../Box'
import isTouchDevice from '../../util/isTouchDevice'
// import DropdownMenu from '../DropdownMenu/DropdownMenu'
import MenuItem from '../MenuItem/MenuItem'
import { MenuItemsProps } from './types'
import { Text } from '../Text'
// import AnimatedIconComponent from '../Svg/AnimatedIconComponent'

const StyledText = styled(Text)`
  font-size: 18px;
  font-weight: 700;
  position: relative;
`

const StyledIconWrapper = styled.div<{ isActive?: boolean }>`
  display: flex;
  align-items: center;
  margin-right: 8px;
`

const SoonLabel = styled(Text)`
  position: absolute;
  right: 0;
  top: 0;
  transform: translate(calc(100% + 0px), -50%);
`

const StyledNav = styled(Flex)`
  background: var(--input-color);
  padding: 12px 20px;
  border-radius: 10px;
  border: 1px solid rgba(251, 238, 185, 0.5);
  /* box-shadow: inset 0px 0px 30px 10px rgba(77, 68, 135, 0.8); */
  justify-content: space-between;
`

const MenuItems: React.FC<MenuItemsProps> = ({ items = [], activeItem, activeSubItem, ...props }) => {
  return (
    <StyledNav alignItems="center" {...props}>
      {items.map(({ label, items: menuItems = [], href, icon, fillIcon, commingSoon }, i) => {
        const statusColor = menuItems?.find((menuItem) => menuItem.status !== undefined)?.status?.color
        const isActive = activeItem === href
        const linkProps = isTouchDevice() && menuItems && menuItems.length > 0 ? {} : { href }
        const Icon = icon
        return (
          // <DropdownMenu key={`${label}#${href}`} items={menuItems} py={1} activeItem={activeSubItem}>
          <MenuItem {...linkProps} isActive={isActive} statusColor={statusColor} isComming={commingSoon} key={i}>
            {/* {icon && icon && createElement(Icon as any, { color: isActive ? 'secondary' : 'white' })} */}
            <StyledText color={isActive ? 'activeLink' : 'white'}>
              {label}

              <SoonLabel fontSize="10px" color="#cecece">
                {commingSoon ? 'Soon' : null}
              </SoonLabel>
            </StyledText>
          </MenuItem>
          // </DropdownMenu>
        )
      })}
    </StyledNav>
  )
}

export default memo(MenuItems)
