import styled from 'styled-components'
import { Text } from '../Text'

export const StyledBottomNavItem = styled.button<{ commingSoon?: boolean }>`
  display: block;
  border: 0;
  background: transparent;
  cursor: ${({ commingSoon }) => (commingSoon ? 'not-allowed' : 'pointer')};
  pointer-events: ${({ commingSoon }) => (commingSoon ? 'none' : 'all')};
  height: 100%;

  &:hover {
    border-radius: 8px;
  }
  &:hover,
  &:hover div {
    background: transparent;
  }
`

export const StyledBottomNavText = styled(Text)`
  display: -webkit-box;
  overflow: hidden;
  user-select: none;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  -webkit-user-select: none;
  -webkit-touch-callout: none;
`

export const Soon = styled(Text)`
  position: absolute;
  right: 10px;
  top: 5px;
`
