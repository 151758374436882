const variants = {
  warning: {
    background: 'transparent',
    borderColor: 'var(--button-color)',
    iconColor: 'transparent',
  },
  danger: {
    background: '#ED4B9E19',
    borderColor: '#7A72AF',
    iconColor: 'transparent',
  },
  success: {
    background: 'rgba(49, 208, 170, 0.1)',
    borderColor: '#7A72AF',
    iconColor: 'transparent',
  },
}

export default variants
