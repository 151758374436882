import React from 'react'
import Svg from '../Svg'
import { SvgProps } from '../types'

const Icon: React.FC<SvgProps> = (props) => {
  return (
    <Svg {...props} xmlns="http://www.w3.org/2000/svg" width="17" height="17" fill="none" viewBox="0 0 17 17">
      <g clipPath="url(#clip0_2644_7651)">
        <path d="M11.688 0a1.066 1.066 0 00-.754 1.816l1.374 1.371-5.62 5.625a1.064 1.064 0 001.503 1.504l5.621-5.624 1.375 1.374a1.063 1.063 0 001.816-.754v-4.25C17.003.476 16.53 0 15.941 0h-4.254zM2.655 1.063A2.656 2.656 0 000 3.719v10.625A2.656 2.656 0 002.656 17h10.625a2.656 2.656 0 002.656-2.656v-3.719a1.061 1.061 0 10-2.124 0v3.719c0 .292-.24.531-.532.531H2.656a.533.533 0 01-.531-.531V3.719c0-.292.24-.531.531-.531h3.719a1.061 1.061 0 100-2.126H2.656z" />
      </g>
      <defs>
        <clipPath id="clip0_2644_7651">
          <path d="M0 0H17V17H0z" />
        </clipPath>
      </defs>
    </Svg>
  )
}

export default Icon
