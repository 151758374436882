import React, { useState, memo } from 'react'
import BottomNavItem from '../BottomNavItem'
import StyledBottomNav, { StyledOtherMenuNav } from './styles'
import { Box } from '../Box'
import DropdownMenu from '../DropdownMenu/DropdownMenu'
import { BottomNavProps } from './types'
import { NotificationDot } from '../NotificationDot'
import { Overlay } from '../Overlay'
import { Button } from '../Button'

const displayCount = 4

const BottomNav: React.FC<BottomNavProps> = ({ items = [], activeItem = '', activeSubItem = '', ...props }) => {
  const [menuOpenByIndex, setMenuOpenByIndex] = useState({})
  const isBottomMenuOpen = Object.values(menuOpenByIndex).reduce((acc, value) => acc || value, false)
  const displayMenu = items.slice(0, displayCount)
  const otherMenu = items.slice(displayCount)
  const [showOtherMenu, setShowOtherMenu] = useState(false)

  const onOpen = () => setShowOtherMenu((prev) => !prev)

  return (
    <>
      {isBottomMenuOpen && <Overlay />}
      <StyledBottomNav {...props}>
        {displayMenu.map(
          (
            {
              label,
              items: menuItems,
              href,
              icon,
              fillIcon,
              showOnMobile = true,
              showItemsOnMobile = true,
              commingSoon,
            },
            index,
          ) => {
            const statusColor = menuItems?.find((menuItem) => menuItem.status !== undefined)?.status?.color
            const isActive = href === activeItem

            return (
              showOnMobile && (
                <DropdownMenu
                  key={label}
                  items={menuItems}
                  // isBottomNav
                  activeItem={activeSubItem}
                  showItemsOnMobile={showItemsOnMobile}
                  // setMenuOpenByIndex={setMenuOpenByIndex}
                  index={index}
                  height="100%"
                  width="100%"
                >
                  <Box height="100%">
                    {/* <NotificationDot show={!!statusColor} color={statusColor}> */}
                    <BottomNavItem
                      href={href}
                      isActive={isActive}
                      label={label}
                      icon={icon}
                      fillIcon={fillIcon}
                      showItemsOnMobile={showItemsOnMobile}
                      commingSoon={commingSoon}
                    />
                    {/* </NotificationDot> */}
                  </Box>
                </DropdownMenu>
              )
            )
          },
        )}
        <Button variant="text" padding="0" maxWidth="50px" width="100%" height="100%" onClick={onOpen}>
          <img src="/images/dropdown-dots.svg" alt="" />
        </Button>

        {/* {showOtherMenu ? ( */}
        <StyledOtherMenuNav>
          {otherMenu.map(
            (
              {
                label,
                items: menuItems,
                href,
                icon,
                fillIcon,
                showOnMobile = true,
                showItemsOnMobile = true,
                commingSoon,
              },
              index,
            ) => {
              const isActive = href === activeItem

              return (
                showOnMobile &&
                showOtherMenu && (
                  <DropdownMenu
                    key={label}
                    items={menuItems}
                    // isBottomNav
                    activeItem={activeSubItem}
                    showItemsOnMobile={showItemsOnMobile}
                    // setMenuOpenByIndex={setMenuOpenByIndex}
                    index={index}
                    height="100%"
                    width="100%"
                  >
                    <Box height="100%">
                      <BottomNavItem
                        href={href}
                        isActive={isActive}
                        label={label}
                        icon={icon}
                        fillIcon={fillIcon}
                        showItemsOnMobile={showItemsOnMobile}
                        commingSoon={commingSoon}
                        rowVariant
                      />
                    </Box>
                  </DropdownMenu>
                )
              )
            },
          )}
        </StyledOtherMenuNav>
      </StyledBottomNav>
    </>
  )
}

export default memo(BottomNav)
