import React from 'react'
// import styled from 'styled-components'
// import { baseColors, darkColors, lightColors } from '../../theme/colors'
import {
  Flex,
  // Box
} from '../Box'
import { Link } from '../Link'
import {
  StyledFooter,
  // StyledIconMobileContainer,
  // StyledList,
  // StyledListItem,
  // StyledText,
  // StyledSocialLinks,
  // StyledToolsContainer,
} from './styles'
import { FooterProps } from './types'
// import { ThemeSwitcher } from '../ThemeSwitcher'
// import LangSelector from '../LangSelector/LangSelector'
// import CakePrice from '../CakePrice/CakePrice'
import {
  // LogoWithTextIcon,
  // ArrowForwardIcon,
  TelegramIcon,
  TwitterIcon,
  DiscordIcon,
  // GithubIcon,
  ChartSocialIcon,
  DocsSocialIcon,
} from '../Svg'
// import { Button } from '../Button'

const MenuItem: React.FC<FooterProps> = ({
  // items,
  // isDark,
  // toggleTheme,
  // currentLang,
  // langs,
  // setLang,
  // cakePriceUsd,
  // buyCakeLabel,
  ...props
}) => {
  return (
    <StyledFooter mb="20px" {...props} justifyContent="center" mt="auto">
      <Flex style={{ gap: '24px' }} justifyContent="center" mt="20px" alignItems="center">
        <Link href="https://discord.gg/SEHBs4kYta" external>
          <DiscordIcon color="white" width="32" />
        </Link>
        <Link href="https://t.me/BeraSwap_Group" external>
          <TelegramIcon width="36" />
        </Link>
        <Link href="https://x.com/BeraSwap_Defi" external>
          <TwitterIcon width="36" />
        </Link>
        <Link href="https://docs.beraswap.fi/" external>
          <ChartSocialIcon width="32" color="transparent" />
        </Link>
        <Link href="/" external>
          <DocsSocialIcon width="36" color="transparent" />
        </Link>
      </Flex>

      <Flex maxWidth={['262px', , , , '377px']} mx="auto" mt="36px" mb="86px">
        <img src="/images/footer-logo.svg" alt="footer-logo" />
      </Flex>
    </StyledFooter>
  )
}

export default MenuItem
