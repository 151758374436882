import React, { useContext, createElement } from 'react'
import { MenuContext } from '../../widgets/Menu/context'
import { Flex } from '../Box'
// import AnimatedIconComponent from '../Svg/AnimatedIconComponent'
import { StyledBottomNavItem, StyledBottomNavText, Soon } from './styles'
import { BottomNavItemProps } from './types'

const BottomNavItem: React.FC<BottomNavItemProps> = ({
  label,
  icon,
  fillIcon,
  href,
  showItemsOnMobile = false,
  isActive = false,
  commingSoon,
  rowVariant = false,
  ...props
}) => {
  const { linkComponent } = useContext(MenuContext)
  const Icon = icon

  const bottomNavItemContent = (
    <Flex
      style={{
        minWidth: '60px',
        background: isActive ? 'rgba(62, 62, 62, 0.72)' : 'transparent',
        padding: '4px 16px',
        gap: rowVariant ? '8px' : '0px',
      }}
      flexDirection={rowVariant ? 'row' : 'column'}
      justifyContent={rowVariant ? 'flex-start' : 'center'}
      alignItems="center"
      height="100%"
      position="relative"
    >
      {/* {icon && <AnimatedIconComponent icon={icon} fillIcon={fillIcon} height="30px" width="30px" isActive={isActive} />} */}
      <Flex width="30px" height="30px" flex="0 0 auto" mb="6px">
        {icon && icon && createElement(Icon as any, { color: isActive ? 'secondary' : 'white', width: '25px' })}
      </Flex>

      <StyledBottomNavText color={isActive ? 'white' : 'white'} fontWeight={isActive ? '700' : '700'} small>
        {label}
      </StyledBottomNavText>
      {commingSoon ? (
        <Soon fontSize="8px" color="white">
          Soon
        </Soon>
      ) : null}
    </Flex>
  )

  return showItemsOnMobile ? (
    <StyledBottomNavItem commingSoon={commingSoon} type="button" {...props}>
      {bottomNavItemContent}
    </StyledBottomNavItem>
  ) : (
    <StyledBottomNavItem commingSoon={commingSoon} as={linkComponent} href={href} {...props}>
      {bottomNavItemContent}
    </StyledBottomNavItem>
  )
}

export default BottomNavItem
