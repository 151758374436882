import React from 'react'
import Svg from '../Svg'
import { SvgProps } from '../types'

const Icon: React.FC<SvgProps> = (props) => {
  return (
    <Svg viewBox="0 0 20 17" {...props}>
      <path
        d="M18.2897 4.37V2.375C18.2897 1.4125 17.4202 0.625 16.3574 0.625H2.8308C1.75834 0.625 0.898438 1.4125 0.898438 2.375V14.625C0.898438 15.5875 1.75834 16.375 2.8308 16.375H16.3574C17.4202 16.375 18.2897 15.5875 18.2897 14.625V12.63C18.8598 12.3237 19.2559 11.7725 19.2559 11.125V5.875C19.2559 5.2275 18.8598 4.67625 18.2897 4.37ZM17.3236 5.875V11.125H10.5603V5.875H17.3236ZM2.8308 14.625V2.375H16.3574V4.125H10.5603C9.49747 4.125 8.62791 4.9125 8.62791 5.875V11.125C8.62791 12.0875 9.49747 12.875 10.5603 12.875H16.3574V14.625H2.8308Z"
        fill="white"
      />
      <path
        d="M13.4588 9.8125C14.2592 9.8125 14.9081 9.22487 14.9081 8.5C14.9081 7.77513 14.2592 7.1875 13.4588 7.1875C12.6584 7.1875 12.0095 7.77513 12.0095 8.5C12.0095 9.22487 12.6584 9.8125 13.4588 9.8125Z"
        fill="white"
      />
    </Svg>
  )
}

export default Icon
