import React from 'react'
import Svg from '../Svg'
import { SvgProps } from '../types'

const Icon: React.FC<SvgProps> = (props) => (
  <Svg width="30" height="30" viewBox="0 0 30 30" {...props}>
    <path
      d="M28.3333 16.6667C29.25 16.6667 30 15.9167 30 15C30 14.0833 29.25 13.3333 28.3333 13.3333H26.6667V3.33333H28.3333C29.25 3.33333 30 2.58333 30 1.66667C30 0.75 29.25 0 28.3333 0H1.66667C0.75 0 0 0.75 0 1.66667C0 2.58333 0.75 3.33333 1.66667 3.33333H3.33333V13.3333H1.66667C0.75 13.3333 0 14.0833 0 15C0 15.9167 0.75 16.6667 1.66667 16.6667H3.33333V26.6667H1.66667C0.75 26.6667 0 27.4167 0 28.3333C0 29.25 0.75 30 1.66667 30H28.3333C29.25 30 30 29.25 30 28.3333C30 27.4167 29.25 26.6667 28.3333 26.6667H26.6667V16.6667H28.3333ZM15 21.6667C12.2333 21.6667 10 19.4667 10 16.75C10 14.5833 10.8667 13.9667 15 9.16667C19.1167 13.9333 20 14.5667 20 16.75C20 19.4667 17.7667 21.6667 15 21.6667Z"
      fill="#FFAA00"
    />
  </Svg>
)

export default Icon
