import React from 'react'
import Svg from '../Svg'
import { SvgProps } from '../types'

const Icon: React.FC<SvgProps> = (props) => (
  <Svg viewBox="0 0 22 22" {...props}>
    <path
      d="M19.381 10.4762H17.8095V6.28571C17.8095 5.13333 16.8667 4.19048 15.7143 4.19048H11.5238V2.61905C11.5238 1.17333 10.3505 0 8.90476 0C7.45905 0 6.28571 1.17333 6.28571 2.61905V4.19048H2.09524C0.942857 4.19048 0.0104762 5.13333 0.0104762 6.28571V10.2667H1.57143C3.13238 10.2667 4.4 11.5343 4.4 13.0952C4.4 14.6562 3.13238 15.9238 1.57143 15.9238H0V19.9048C0 21.0571 0.942857 22 2.09524 22H6.07619V20.4286C6.07619 18.8676 7.34381 17.6 8.90476 17.6C10.4657 17.6 11.7333 18.8676 11.7333 20.4286V22H15.7143C16.8667 22 17.8095 21.0571 17.8095 19.9048V15.7143H19.381C20.8267 15.7143 22 14.541 22 13.0952C22 11.6495 20.8267 10.4762 19.381 10.4762Z"
      fill="#FFAA00"
    />
  </Svg>
)

export default Icon
