import React from 'react'
import Svg from '../Svg'
import { SvgProps } from '../types'

const Icon: React.FC<SvgProps> = (props) => {
  return (
    <Svg {...props} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 29 30" fill="none">
      <path d="M7.14509 12.8574H2.85938V22.8574H7.14509V12.8574Z" fill="#FFAA00" />
      <path d="M16.4342 12.8574H12.1484V22.8574H16.4342V12.8574Z" fill="#FFAA00" />
      <path d="M28.5714 25.7139H0V29.9996H28.5714V25.7139Z" fill="#FFAA00" />
      <path d="M25.7154 12.8574H21.4297V22.8574H25.7154V12.8574Z" fill="#FFAA00" />
      <path d="M14.2857 0L0 7.14286V10H28.5714V7.14286L14.2857 0Z" fill="#FFAA00" />
    </Svg>
  )
}

export default Icon
