import {
  Box,
  Flex,
  FlexProps,
  Link,
  LogoutIcon,
  OpenNewIcon,
  Text,
  UserMenuItem,
  VelasIcon,
  MetamaskIcon,
} from 'packages/uikit'
import styled from 'styled-components'
import { useTranslation } from 'contexts/Localization'
// import Image from 'next/image'
import { getBscScanLink } from 'utils'
// import { useWeb3React } from '@web3-react/core'
import useAuth from 'hooks/useAuth'
import useTokenBalance, { useGetBnbBalance } from 'hooks/useTokenBalance'
import tokens from 'config/constants/tokens'
import { FlexGap } from 'components/Layout/Flex'
import { formatBigNumber, getFullDisplayBalance } from 'utils/formatBalance'
import { Currency } from '@beraswap-dex/sdk'
import { CurrencyLogo } from 'components/Logo'
import { CopyButton } from '../../CopyButton'

interface CopyAddressProps extends FlexProps {
  account: string
  currency?: Currency | null
}

const Wrapper = styled(Flex)`
  background-color: transparent;
  border-radius: 8px;
  position: relative;
  flex-direction: column;
`

const Address = styled.div`
  flex: 1;
  position: relative;

  & > input {
    background: transparent;
    border: 0;
    color: ${({ theme }) => theme.colors.text};
    display: block;
    font-weight: 500;
    font-size: 16px;
    padding: 0;
    width: 100%;

    &:focus {
      outline: 0;
    }
  }

  &:after {
    content: '';
    height: 100%;
    pointer-events: none;
    position: absolute;
    right: 0;
    top: 0;
    width: 40px;
  }
`

const StyledBox = styled(Box)`
  background: var(--input-color);
  padding: 10px 12px;
  margin-top: 16px;
  border-radius: 10px;
`

const Hr = styled.div`
  width: 100%;
  height: 1px;
  margin: auto;
  background: #fff;
`

const Action = styled(Flex)`
  gap: 20px;
  flex-direction: row;
  justify-content: flex-start;

  ${({ theme }) => theme.mediaQueries.md} {
    justify-content: space-between;
  }
`

const CopyAddress: React.FC<CopyAddressProps> = ({ account, currency, ...props }) => {
  const { t } = useTranslation()
  const accountEllipsis = account ? `${account.substring(0, 9)}...${account.substring(account.length - 9)}` : null
  const { logout } = useAuth()
  const { balance, fetchStatus } = useGetBnbBalance()
  const { balance: cakeBalance, fetchStatus: cakeFetchStatus } = useTokenBalance(tokens.cake.address)

  return (
    <StyledBox position="relative" {...props}>
      <Wrapper>
        <Flex alignItems="center">
          <Flex justifyContent="center" flex="0" mr="6px" background="black" borderRadius="50%" padding="6px">
            {/* <Image width={24} height={24} src="/logo.png" /> */}
            <MetamaskIcon width="24px" />
          </Flex>

          <Address title={account}>
            <Text fontSize="18px">{accountEllipsis}</Text>
          </Address>
        </Flex>

        <Action alignItems="center" padding="10px 0">
          <Flex alignItems="center">
            <CopyButton width="18px" text={account} tooltipMessage={t('Copied')} tooltipTop={-40} />
            <Text ml="5px" fontSize="14px" display={['none', , , , 'block']} ellipsis>
              {t('Copy Address')}
            </Text>
          </Flex>

          <Flex alignItems="center">
            <OpenNewIcon color="white" width={24} />

            <Link ml="5px" external href={getBscScanLink(account, 'address')}>
              <Text fontSize="14px" display={['none', , , , 'block']} ellipsis>
                View Explore
              </Text>
            </Link>
          </Flex>

          <UserMenuItem style={{ width: 'auto', padding: '0' }} as="button" onClick={logout}>
            <Flex alignItems="center" width="auto">
              <LogoutIcon mr="5px" color="transparent" />

              <Text fontSize="14px" display={['none', , , , 'block']}>
                {t('Disconnect')}
              </Text>
            </Flex>
          </UserMenuItem>
        </Action>
        <Hr />

        <FlexGap gap="10px" mt="15px" flexDirection="column">
          <Text>{t('Your Balance')}</Text>
          <Flex justifyContent="space-between" width="100%">
            <Flex alignItems="center">
              <VelasIcon width={30} />
              <Text ml="5px" bold>
                BERA
              </Text>
            </Flex>
            <Text bold>{formatBigNumber(balance, 6)}</Text>
          </Flex>

          <Flex justifyContent="space-between" width="100%">
            <Flex alignItems="center">
              {/* <VelasIcon /> */}
              <CurrencyLogo currency={tokens.cake} size="30px" />
              <Text ml="5px" bold>
                {tokens.cake.symbol}
              </Text>
            </Flex>
            <Text bold>{getFullDisplayBalance(cakeBalance, 18, 6)}</Text>
          </Flex>
        </FlexGap>
      </Wrapper>
    </StyledBox>
  )
}

export default CopyAddress
