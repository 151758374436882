import styled, { DefaultTheme } from 'styled-components'
import { Colors } from '../../theme'
import { Text } from '../Text'
import { StyledDropdownMenuItemProps } from './types'

const getTextColor = ({
  $isActive,
  disabled,
  theme,
}: StyledDropdownMenuItemProps & { theme: DefaultTheme; $isActive: boolean }) => {
  if (disabled) return '#D2D2D2'
  if ($isActive) return 'rgba(217, 217, 217, 1)'

  return 'var(--button-color)'
}

export const DropdownMenuItem = styled.button<StyledDropdownMenuItemProps & { $isActive: boolean }>`
  align-items: center;
  border: 0;
  background: white;
  color: ${({ theme, disabled, $isActive }) => getTextColor({ theme, disabled, $isActive })};
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  /* font-weight: ${({ $isActive = false }) => ($isActive ? '700' : '400')}; */
  font-weight: 700;
  display: flex;
  font-size: 16px;
  height: 48px;
  justify-content: space-between;
  outline: 0;
  padding-left: 16px;
  padding-right: 16px;
  width: 100%;

  &:hover:not(:disabled) {
    opacity: 1;
    color: rgba(217, 217, 217, 1);
    font-weight: 700;
  }

  &:active:not(:disabled) {
    opacity: 1;
    transform: translateY(1px);
  }
`

export const StyledDropdownMenuItemContainer = styled.div`
  &:first-child > ${DropdownMenuItem} {
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
  }

  &:last-child > ${DropdownMenuItem} {
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
  }
`

export const DropdownMenuDivider = styled.hr`
  border-color: ${({ theme }) => theme.colors.cardBorder};
  border-style: solid;
  border-width: 1px 0 0;
  margin: 4px 0;
`

export const StyledDropdownMenu = styled.div<{ $isOpen: boolean; $isBottomNav: boolean }>`
  border-radius: 10px;
  pointer-events: auto;
  width: ${({ $isBottomNav }) => ($isBottomNav ? 'calc(100% - 32px)' : '180px')};
  visibility: visible;
  z-index: 1001;

  ${({ $isOpen }) =>
    !$isOpen &&
    `
    pointer-events: none;
    visibility: hidden;
  `}
`

export const LinkStatus = styled(Text)<{ color: keyof Colors }>`
  border-radius: ${({ theme }) => theme.radii.default};
  padding: 0 8px;
  border: 2px solid;
  border-color: ${({ theme, color }) => theme.colors[color]};
  box-shadow: none;
  color: ${({ theme, color }) => theme.colors[color]};
  margin-left: 8px;
`
