import throttle from 'lodash/throttle'
import React, { useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import BottomNav from '../../components/BottomNav'
import { Box } from '../../components/Box'
import Flex from '../../components/Box/Flex'
// import Footer from '../../components/Footer'
import MenuItems from '../../components/MenuItems/MenuItems'
// import { SubMenuItems } from '../../components/SubMenuItems'
import { useMatchBreakpoints } from '../../hooks'
// import CakePrice from '../../components/CakePrice/CakePrice'
import Logo from './components/Logo'
import { MENU_HEIGHT, TOP_BANNER_HEIGHT, TOP_BANNER_HEIGHT_MOBILE } from './config'
import { NavProps } from './types'
// import LangSelector from '../../components/LangSelector/LangSelector'
import { MenuContext } from './context'
// import { SubMenuItems } from '../../components/SubMenuItems'
import { ChartSocialIcon, DocsSocialIcon, TelegramIcon, TwitterIcon, DiscordIcon } from '../../components/Svg'
import { Link } from '../../components/Link'
import Footer from '../../components/Footer'
import { Text } from '../../components/Text'
// import AnimatedIconComponent from '../../components/Svg/AnimatedIconComponent'

const Wrapper = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
  min-height: 100vh;

  &:before {
    content: '';
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;

    width: 100%;
    height: 100%;

    background: url('/images/bg-brs.svg') no-repeat;
    background-size: cover;
    /* background-position: center calc(100% + 10px); */
  }
`

const StyledNav = styled.nav`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: auto;

  width: 100%;
  height: 100%;
  background-color: transparent;
  transform: translate3d(0, 0, 0);

  position: relative;
  padding-left: 16px;
  padding-right: 16px;

  ${({ theme }) => theme.mediaQueries.md} {
    padding-left: 36px;
    padding-right: 36px;
  }
`

export const BorderSpan = styled.div`
  position: absolute;
  bottom: 0;
  width: 12px;
  height: calc(100% - 20px);
  background: #cd7104;

  &.left {
    left: 0;
    border-top-right-radius: 12px;

    &:before {
      content: '';
      position: absolute;
      right: 0;
      bottom: 0;
      transform: translateX(100%);

      width: 15px;
      height: 15px;
      background: #cd7104;
    }

    &:after {
      content: '';
      position: absolute;
      right: 0;
      bottom: 0;
      transform: translateX(100%);

      width: 15px;
      height: 15px;
      background: var(--primary-color);
      border-radius: 0px 0px 0px 86px;
    }
  }

  &.right {
    right: 0;
    border-top-left-radius: 12px;

    &:before {
      content: '';
      position: absolute;
      left: 0;
      bottom: 0;
      transform: translateX(-100%);

      width: 15px;
      height: 15px;
      background: #cd7104;
    }

    &:after {
      content: '';
      position: absolute;
      left: 0;
      bottom: 0;
      transform: translateX(-100%);

      width: 15px;
      height: 15px;
      background: var(--primary-color);
      border-radius: 0px 0 86px 0px;
    }
  }
`

const FixedContainer = styled.div<{ showMenu: boolean; height: number }>`
  /* position: fixed; */
  /* top: ${({ showMenu, height }) => (showMenu ? 0 : `-${height}px`)};
  left: 0; */
  transition: top 0.2s;
  height: ${({ height }) => `${height}px`};
  z-index: 20;
  height: 80px;
  background: var(--primary-color);

  /* max-width: 1920px;
  width: 100%;
  height: auto;
  max-height: 86px;
  aspect-ratio: calc(1080 / 86); */

  ${({ theme }) => theme.mediaQueries.xxs} {
    margin: 0;
    border: 0;
  }

  /* ${({ theme }) => theme.mediaQueries.md} {
    background: url('/images/bg_nav.svg') no-repeat;
    background-size: cover;
  } */
`

// const TopBannerContainer = styled.div<{ height: number }>`
//   height: ${({ height }) => `${height}px`};
//   min-height: ${({ height }) => `${height}px`};
//   max-height: ${({ height }) => `${height}px`};
//   width: 100%;
// `

const BodyWrapper = styled(Box)`
  position: relative;
  display: flex;
  flex-direction: column;
  min-height: calc(100vh - 92px);
`

const Inner = styled.div<{ isPushed: boolean; showMenu: boolean }>`
  flex-grow: 1;
  transition: margin-top 0.2s, margin-left 0.2s cubic-bezier(0.4, 0, 0.2, 1);
  transform: translate3d(0, 0, 0);
  max-width: 100%;
  background: transparent;

  display: flex;
  flex-direction: column;
`

const StyledFlex = styled(Flex)`
  flex-direction: column;
  position: fixed;
  z-index: 9;
  top: 50%;
  right: -5px;
  transform: translateY(-50%);
  background: url('/images/bg_socials.png') no-repeat;
  background-size: 100% 100%;
  padding: 60px 15px;

  ${({ theme }) => theme.mediaQueries.sm} {
    gap: 5px;
  }

  ${({ theme }) => theme.mediaQueries.md} {
    gap: 36px;
  }
`

const DesktopMenuItems = styled(Flex)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`

const LogoText = styled(Text).attrs({ fontFamily: 'Chango', fontSize: '18px', ml: '6px' })`
  /* background: linear-gradient(180deg, var(--primary-color) 0%, #ededef 74%, #ffffff 100%); */
  background: linear-gradient(180deg, #8e4719 0%, #ead39f 74.5%, #fbeeb9 100%);
  -webkit-background-clip: text;
  color: transparent;
`

const Menu: React.FC<NavProps> = ({
  linkComponent = 'a',
  userMenu,
  banner,
  globalMenu,
  isDark,
  toggleTheme,
  currentLang,
  setLang,
  cakePriceUsd,
  links,
  subLinks,
  footerLinks,
  activeItem,
  activeSubItem,
  langs,
  buyCakeLabel,
  children,
  decribe,
}) => {
  const { isMobile, isMd } = useMatchBreakpoints()
  const [showMenu, setShowMenu] = useState(true)
  const refPrevOffset = useRef(typeof window === 'undefined' ? 0 : window.pageYOffset)

  const topBannerHeight = isMobile ? TOP_BANNER_HEIGHT_MOBILE : TOP_BANNER_HEIGHT

  const totalTopMenuHeight = banner ? MENU_HEIGHT + topBannerHeight : MENU_HEIGHT

  useEffect(() => {
    const handleScroll = () => {
      const currentOffset = window.pageYOffset
      const isBottomOfPage = window.document.body.clientHeight === currentOffset + window.innerHeight
      const isTopOfPage = currentOffset === 0
      // Always show the menu when user reach the top
      if (isTopOfPage) {
        setShowMenu(true)
      }
      // Avoid triggering anything at the bottom because of layout shift
      else if (!isBottomOfPage) {
        if (currentOffset < refPrevOffset.current || currentOffset <= totalTopMenuHeight) {
          // Has scroll up
          setShowMenu(true)
        } else {
          // Has scroll down
          setShowMenu(false)
        }
      }
      refPrevOffset.current = currentOffset
    }
    const throttledHandleScroll = throttle(handleScroll, 200)

    window.addEventListener('scroll', throttledHandleScroll)
    return () => {
      window.removeEventListener('scroll', throttledHandleScroll)
    }
  }, [totalTopMenuHeight])

  // Find the home link if provided
  const homeLink = links.find((link) => link.label === 'Home')

  const subLinksWithoutMobile = subLinks?.filter((subLink) => !subLink.isMobileOnly)
  const subLinksMobileOnly = subLinks?.filter((subLink) => subLink.isMobileOnly)

  const [isBottomNavOpen, setIsBottomNavOpen] = useState(false)
  const bottomNavRef = useRef<HTMLDivElement | null>(null)

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (bottomNavRef.current && !bottomNavRef.current.contains(event.target as Node)) {
        setIsBottomNavOpen(false)
      }
    }

    document.addEventListener('click', handleClickOutside)

    return () => {
      document.removeEventListener('click', handleClickOutside)
    }
  }, [bottomNavRef])

  const decribeIcon = decribe?.icon

  return (
    <MenuContext.Provider value={{ linkComponent }}>
      <Wrapper>
        {!isMobile ? (
          <FixedContainer width="100%" showMenu={showMenu} height="80">
            <StyledNav width="100%">
              <BorderSpan className="left" />
              <Flex justifyContent="space-between" width="100%">
                <Logo isDark={isDark} href={homeLink?.href ?? '/'} />

                <DesktopMenuItems margin="auto" height="48px">
                  {!isMobile && <MenuItems items={links} activeItem={activeItem} activeSubItem={activeSubItem} />}
                </DesktopMenuItems>

                <Flex alignItems="center" style={{ gap: '10px' }}>
                  <Box>{globalMenu}</Box>
                  {userMenu}
                </Flex>
              </Flex>
              <BorderSpan className="right" />
            </StyledNav>
          </FixedContainer>
        ) : null}

        {isMobile && (
          <FixedContainer showMenu={showMenu} height="auto">
            <StyledNav width="100%">
              <Logo isDark={isDark} href={homeLink?.href ?? '/'} />
              {!isMobile && <MenuItems mt="20px" items={links} activeItem={activeItem} activeSubItem={activeSubItem} />}
              <Flex style={{ gap: '10px' }}>
                {globalMenu}
                {userMenu}
              </Flex>
            </StyledNav>
          </FixedContainer>
        )}

        <Flex pt="12px" width="100%" flexDirection="column">
          {!isMobile ? (
            <StyledFlex alignItems="center">
              <Link href="https://discord.gg/SEHBs4kYta" external>
                <DiscordIcon width="32" color="white" />
              </Link>
              <Link href="https://t.me/BeraSwap_Group" external>
                <TelegramIcon width="36" />
              </Link>
              <Link href="https://x.com/BeraSwap_Defi" external>
                <TwitterIcon width="36" />
              </Link>
              <Link href="https://docs.beraswap.fi/" external>
                <ChartSocialIcon width="32" color="transparent" />
              </Link>
              <Link href="/" external>
                <DocsSocialIcon width="36" color="transparent" />
              </Link>
            </StyledFlex>
          ) : null}

          <BodyWrapper>
            <Inner isPushed={false} showMenu={showMenu}>
              {children}

              {isMobile ? (
                <Footer
                  items={footerLinks}
                  isDark={isDark}
                  toggleTheme={toggleTheme}
                  langs={langs}
                  setLang={setLang}
                  currentLang={currentLang}
                  cakePriceUsd={cakePriceUsd}
                  buyCakeLabel={buyCakeLabel}
                />
              ) : (
                <Flex maxWidth="377px" mx="auto" mt="2rem">
                  <img src="/images/footer-logo.svg" alt="footer-logo" />
                </Flex>
              )}
            </Inner>
            {isMobile ? <BottomNav items={links} activeItem={activeItem} activeSubItem={activeSubItem} /> : null}
          </BodyWrapper>
        </Flex>
      </Wrapper>
    </MenuContext.Provider>
  )
}

export default Menu
