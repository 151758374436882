import { ChainId, Token } from '@beraswap-dex/sdk'
import { serializeToken } from 'state/user/hooks/helpers'
import { CHAIN_ID } from './networks'
import { SerializedToken } from './types'
import addresses from './addresses.json'

const { MAINNET, TESTNET } = ChainId

interface TokenList {
  [symbol: string]: Token
}

const defineTokens = <T extends TokenList>(t: T) => t

export const mainnetTokens = {
  cake: new Token(MAINNET, addresses[MAINNET].PlatformToken, 18, 'BRS', 'Beraswap', 'https://beraswap.fi'),
  weth: new Token(MAINNET, addresses[MAINNET].WETH, 18, 'WBERA', 'Wrapper BERA', 'https://beraswap.fi'),
  busd: new Token(MAINNET, '0x95512957E31cCE312ac2e6407e562228d3BC331b', 18, 'BUSD', 'BUSD', 'https://beraswap.fi'),
  usdc: new Token(MAINNET, '0x174c4c03dfea09682728a5959a253bf1f7c7766f', 18, 'USDC', 'USD coin', 'https://beraswap.fi'),
  nect: new Token(
    MAINNET,
    '0xf5afcf50006944d17226978e594d4d25f4f92b40',
    18,
    'NECT',
    'Nectar Token',
    'https://beraswap.fi',
  ),
  honey: new Token(MAINNET, '0x0e4aaf1351de4c0264c5c7056ef3777b41bd8e03', 18, 'HONEY', 'Honey', 'https://beraswap.fi'),
}

export const testnetTokens = {
  cake: new Token(TESTNET, addresses[TESTNET].PlatformToken, 18, 'BRS', 'Beraswap', 'https://beraswap.fi'),
  weth: new Token(TESTNET, addresses[TESTNET].WETH, 18, 'WBERA', 'Wrapper BERA', 'https://beraswap.fi'),
  busd: new Token(TESTNET, '0x95512957E31cCE312ac2e6407e562228d3BC331b', 18, 'BUSD', 'BUSD', 'https://beraswap.fi'),
  usdc: new Token(TESTNET, '0x174c4c03dfea09682728a5959a253bf1f7c7766f', 18, 'USDC', 'USD coin', 'https://beraswap.fi'),
  nect: new Token(
    TESTNET,
    '0xf5afcf50006944d17226978e594d4d25f4f92b40',
    18,
    'NECT',
    'Nectar Token',
    'https://beraswap.fi',
  ),
  honey: new Token(TESTNET, '0x0e4aaf1351de4c0264c5c7056ef3777b41bd8e03', 18, 'HONEY', 'Honey', 'https://beraswap.fi'),
}

const tokens = () => {
  const chainId = CHAIN_ID

  // If testnet - return list comprised of testnetTokens wherever they exist, and mainnetTokens where they don't
  if (parseInt(chainId, 10) === ChainId.TESTNET) {
    return Object.keys(testnetTokens).reduce((accum, key) => {
      return { ...accum, [key]: testnetTokens[key] }
    }, {} as typeof testnetTokens)
    // return Object.keys(mainnetTokens).reduce((accum, key) => {
    //   return { ...accum, [key]: testnetTokens[key] || mainnetTokens[key] }
    // }, {} as typeof testnetTokens & typeof mainnetTokens)
  }

  return mainnetTokens
}

const unserializedTokens = tokens()

type SerializedTokenList = Record<keyof typeof unserializedTokens, SerializedToken>

export const serializeTokens = () => {
  const serializedTokens = Object.keys(unserializedTokens).reduce((accum, key) => {
    return { ...accum, [key]: serializeToken(unserializedTokens[key]) }
  }, {} as SerializedTokenList)

  return serializedTokens as any
}

export default unserializedTokens
