import React from 'react'
import Svg from '../Svg'
import { SvgProps } from '../types'

const Icon: React.FC<SvgProps> = (props) => {
  return (
    <Svg {...props} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 39 30" fill="none">
      <path
        d="M32.3018 16.0303C33.9258 16.0303 35.4101 16.5192 36.6673 17.3574V9.0454C36.6673 7.12456 35.0957 5.55297 33.1749 5.55297H22.1912L20.3402 3.70198L22.8024 1.23981L21.5626 0L15.3984 6.16414L16.6383 7.40395L19.1004 4.94179L20.9514 6.79278V10.7916C20.9514 12.7124 19.3798 14.284 17.459 14.284H16.516C18.1749 16.135 19.2052 18.5797 19.2052 21.2689C19.2052 21.8626 19.1353 22.4389 19.048 23.0151H24.5311C24.9677 19.0861 28.268 16.0303 32.3018 16.0303Z"
        fill="#FFAA00"
      />
      <path
        d="M32.3071 17.7773C28.9369 17.7773 26.1953 20.5189 26.1953 23.8891C26.1953 27.2593 28.9369 30.0008 32.3071 30.0008C35.6773 30.0008 38.4188 27.2593 38.4188 23.8891C38.4188 20.5189 35.6773 17.7773 32.3071 17.7773ZM32.3071 26.5084C30.8577 26.5084 29.6877 25.3385 29.6877 23.8891C29.6877 22.4397 30.8577 21.2698 32.3071 21.2698C33.7564 21.2698 34.9264 22.4397 34.9264 23.8891C34.9264 25.3385 33.7564 26.5084 32.3071 26.5084Z"
        fill="#FFAA00"
      />
      <path
        d="M5.2384 10.7922H13.9695C13.9695 8.8714 12.3979 7.2998 10.477 7.2998H5.2384C4.27798 7.2998 3.49219 8.0856 3.49219 9.04602C3.49219 10.0064 4.27798 10.7922 5.2384 10.7922Z"
        fill="#FFAA00"
      />
      <path
        d="M15.4191 19.2086L15.1048 18.3879L16.7287 17.7767C15.9255 15.9257 14.4936 14.4415 12.695 13.5334L11.9965 15.0876L11.1932 14.7209L11.8917 13.1493C10.9313 12.7651 9.84865 12.5381 8.73108 12.5381C7.80558 12.5381 6.91501 12.7302 6.07683 12.9921L6.67054 14.5812L5.84982 14.8955L5.23865 13.2715C3.38766 14.0748 1.90337 15.5067 0.995343 17.3053L2.54947 18.0037L2.18277 18.807L0.611175 18.1085C0.227008 19.0689 0 20.1516 0 21.2692C0 22.1947 0.192084 23.0852 0.454016 23.9234L2.04307 23.3297L2.35739 24.1504L0.73341 24.7616C1.53667 26.6126 2.96857 28.0969 4.76717 29.0049L5.46565 27.4508L6.26891 27.8175L5.57043 29.3891C6.53085 29.7732 7.6135 30.0002 8.73108 30.0002C9.65657 30.0002 10.5471 29.8082 11.3853 29.5462L10.7916 27.9572L11.6123 27.6428L12.2235 29.2668C14.0745 28.4636 15.5588 27.0317 16.4668 25.2331L14.9127 24.5346L15.2794 23.7313L16.851 24.4298C17.2351 23.4694 17.4622 22.3867 17.4622 21.2692C17.4622 20.3437 17.2701 19.4531 17.0081 18.6149L15.4191 19.2086ZM10.7392 26.1062C8.06751 27.2063 4.99418 25.949 3.89406 23.2773C2.79394 20.6056 4.05122 17.5323 6.72293 16.4321C9.39464 15.332 12.468 16.5893 13.5681 19.261C14.6857 21.9327 13.4109 25.0061 10.7392 26.1062Z"
        fill="#FFAA00"
      />
    </Svg>
  )
}

export default Icon
